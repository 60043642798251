import styled from 'styled-components'
import media from '../utility/media'
import theme from '../utility/theme'

const Text = styled.div`
  margin: 0;
  font-family: ${theme.fontFamily};
  color: ${theme.colorSecondary};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.2px;
  margin-bottom: 25px;
  @media (min-width: ${media.md}) {
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: 1px;
  }
`

export default Text
