import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../../utility/common'
import media from '../../../utility/media'

const StyledFeatures = styled.div`
  margin-top: 45px;
  @media (min-width: ${media.md}) {
    margin-top: 120px;
    padding-bottom: 100px;
  }
  @media (min-width: ${media.lg}) {
    margin-top: 150px;
    padding-bottom: 100px;
  }
`

class Features extends Component {
  componentDidMount() {
    this.pageFeaturesTween = TweenMax.from(this.pageFeatures, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    return (
      <StyledFeatures innerRef={div => (this.pageFeatures = div)}>
        {this.props.children}
      </StyledFeatures>
    )
  }
}

export default Features
