import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../utility/media'
import PageTag from '../components/page-components/PageTag'
import TitleHome from '../components/page-components/index/TitleHome'
import Features from '../components/page-components/index/Features'
import Text from '../components/Text'
import Footer from '../components/page-components/index/Footer'

const titles = []

const Container = styled.div`
  min-height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 280px);
  @media (max-width: ${media.sm}) {
    min-height: calc(100vh - 255px);
  }
`

class Index extends Component {
  render() {
    const { data, isFirstLoad, timingOffset, ...rest } = this.props
    const nodeData = data.allPrismicHome.edges[0].node.data
    const pageTag = nodeData.page_name.text

    for (let item of nodeData.animated_text) {
      titles.push(item.text.text)
    }

    const features = nodeData.about_text.map((item, index) => <Text key={index}>{item.text.text}</Text>
    )

    return (
      <Container>
        <Content>
          <div className="heading">
            <PageTag delay={timingOffset}>{pageTag}</PageTag>
            <TitleHome delay={timingOffset + 0.3} titles={titles} />
          </div>

          <Features delay={timingOffset + 1.2}>
            {features}
          </Features>
        </Content>
        <Footer delay={timingOffset + 1.5} {...rest} />
      </Container>
    )
  }
}


export const query = graphql`
  query {
  allPrismicHome{
    edges{
      node{
        data{
          page_name {
            text
          }
          animated_text{
            text{
              text
            }
          }
          about_text{
            text{
              text
            }
          }
        }
      }
    }
  }
}
`

export default Index
