import React, { Component } from 'react'
import styled from 'styled-components'
import { TimelineMax } from 'gsap/TweenMax'
import { common } from '../../../utility/common'
import media from '../../../utility/media'
import NavButton from '../../NavButton'

const StyledFooter = styled.div`
  height: auto;
  margin-top: 40px;

  @media (min-width: ${media.md}) {
    height: 40px;
    margin-top: 50px;
  }
  @media (min-width: ${media.lg}) {
    margin-top: 0;
    margin-bottom: 30px;
  }
`

const FooterNav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`

const NavItem = styled.li`
  margin-right: 70px;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${media.md}) {
    margin-bottom: 0;
    margin-bottom: 40px;
  }
`

const navItems = [
  {
    path: '/portfolio/',
    text: 'My Works',
  },
  {
    path: '/blog/',
    text: 'Read Me',
  },
  {
    path: '/contact/',
    text: 'Contact Me',
  },
]

class Footer extends Component {
  constructor(props) {
    super(props)
    this.navItems = []
    this.navItemsTween = null
  }

  componentDidMount() {
    this.navItemsTween = new TimelineMax().staggerFrom(
      this.navItems,
      common.duration,
      {
        x: -15,
        autoAlpha: 0,
        ease: common.easing.easeOut,
        delay: this.props.delay,
      },
      0.2
    )
  }

  render() {
    const { startPageChangingHandler } = this.props
    return (
      <StyledFooter>
        <FooterNav>
          {navItems.map((item, i) => {
            const obj = {
              path: item.path,
              onClick: startPageChangingHandler,
            }

            return (
              <NavItem key={i} innerRef={li => (this.navItems[i] = li)}>
                <NavButton {...obj}>{item.text}</NavButton>
              </NavItem>
            )
          })}
        </FooterNav>
      </StyledFooter>
    )
  }
}

export default Footer
